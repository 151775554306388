.inputsContainer {
  display: flex;
}

.inputContainer {
  width: 126px;
  margin-right: 18px;
}

.inputContainer:last-of-type {
  margin-right: 0;
}

.containerWithError {
  width: fit-content;
  margin: auto;
  max-width: 100%;
}
