.inputContainer {
  width: 100%;
  max-width: 414px;
  margin-bottom: 20px;
}

.inputContainer:last-child {
  margin-bottom: 0;
}

.input {
  border: 1px solid var(--grey-300);
  --background: var(--ion-color-light) !important;
  border-radius: 10px;

  --padding-start: 10px;
  --padding-end: 10px !important;
  --padding-top: 8px;
  --padding-bottom: 7px;

  --color: var(--ion-color-dark);
  --placeholder-color: var(--grey-400);
  --placeholder-opacity: 1;

  overflow: hidden;

  height: 60px;
  text-align: center;
}

.input.has-focus {
  border-color: var(--grey-500);
}

.error {
  border-color: var(--red-900);
}
