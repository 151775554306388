.errorBoxContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: -20px;
  margin-bottom: 20px;
}

.errorBox {
  border: 1px solid var(--ion-color-danger);
  padding: 9px 16px;
  width: 630px;
  max-width: 100%;
  text-align: center;
}

.errorIcon {
  font-size: 20px;
  min-width: 18px;
  position: relative;
  top: 4px;
  margin-right: 8px;
}
