.option {
  cursor: pointer;
  user-select: none;
  width: 90px;
  display: flex;
  box-sizing: content-box;
  flex-direction: column;
  align-items: center;
  margin: 0 9px 18px 9px;
}

.moodIcon {
  font-size: 65px;
  min-width: 65px;
  margin-bottom: 18px;
}

.moodText {
  padding-top: 10px;
}

.check {
  height: 24px;
}

@media (max-width: 767px) {
  .option {
    width: 100%;
    flex-direction: row;
    margin: 0 0 24px 0;
  }

  .check {
    display: flex;
    order: -1;
  }

  .moodIcon {
    margin: 0 15px;
  }

  .moodText {
    max-width: 183px;
  }
}
