.choiceContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.optionsColumn {
  display: flex;
  flex-direction: column;
}

.regularOptions .optionsColumn {
  margin-right: 9px;
  margin-left: 9px;
}

.regularOptions {
  margin-bottom: -18px;
}

.checkboxOptions .optionsColumn {
  margin-right: 12px;
  margin-left: 12px;
}

.checkboxOptions {
  margin-bottom: -24px;
}

.cycle {
  flex-direction: row;
  justify-content: center;
}

.withHelperBox .optionsColumn {
  margin-left: 0;
}

.withHelperBox .optionsColumn:last-of-type {
  margin-right: 0;
}

@media (max-width: 767px) {
  .regularOptions .optionsColumn {
    margin-right: 0;
    margin-left: 0;
  }

  .checkboxOptions .optionsColumn {
    margin-right: 0;
    margin-left: 0;
  }
}
@media (max-width: 576px) {
  .optionsColumn {
    width: 100%;
  }
}

