.authContainer {
  --background: none;
  background-color: #1d007e;
  --color: var(--ion-color-light);
  background-position-x: center;
  background-repeat: no-repeat;
  background-size: cover;
  --padding-top: 10px;
  --padding-bottom: 10px;
}

.authCard {
  border-radius: 20px;
  padding: 40px 44px 60px;
  width: 414px;
  max-width: 100%;
  margin: 0 auto;
  font-size: 16px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: var(--ion-color-light-shade);
}

.cardContent {
  width: 100%;
  padding: 0;
  font-size: 16px;
  margin-top: 40px;
}

@media (max-width: 576px) {
  .authCard {
    padding: 20px 24px 40px;
  }
}

.ionGridVerticalAlign {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
