.title {
  font-size: 24px;
  line-height: 34px;
  font-weight: 900;
  white-space: pre-line;
  display: block;
}

.subTitle {
  display: block;
  margin-top: 18px;
}

@media (max-width: 767px) {
  .title {
    font-size: 22px;
    line-height: 33px;
  }

  .subTitle {
    margin-top: 16px;
  }
}
