.option {
  height: fit-content;
  width: 306px;
  box-sizing: content-box;
  margin: 0 0 18px 0;
  --background: var(--ion-color-light);
  --background-activated: var(--ion-color-medium);
  --border-color: var(--ion-color-medium);
  --ripple-color: var(--ion-color-medium);
}

@media (max-width: 576px) {
  .option {
    width: 100%;
  }
}

@media (max-width: 992px) {
  .option {
    padding: 0 !important;
  }
}

.active {
  --background-activated: var(--ion-color-primary);
  --background: var(--purple-100);
  --border-color: var(--ion-color-primary);
  --ripple-color: var(--ion-color-primary);
}

.option__text {
  font-weight: normal;
  white-space: normal !important;
  letter-spacing: normal;
  display: flex;
  line-height: 24px;
  text-transform: none;
  padding: 7px 0;
  min-height: 60px;
  align-items: center;
}

.option__subtext {
}
