.checkbox {
  cursor: pointer;
  display: flex;
  width: 300px;
  margin-bottom: 24px;
}

@media (max-width: 767px) {
  .checkbox {
    width: 100%;
    padding: 0!important;
  }
}

.checkbox ion-text {
  padding-left: 7px;
}
