.card {
  border-radius: 10px;
  margin: 0;
}

.card .cardContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  --color: var(--ion-color-dark);
}
