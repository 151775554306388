.cycleOption {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  user-select: none;
  min-width: 198px;
  margin-bottom: 10px;
}

.cycleOption_mobile {
  flex-direction: row;
  position: relative;
}

.iconContainer {
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
}

.iconContainer_mobile {
  width: 56px;
  height: auto;
  position: static;
  align-self: flex-start;
}

.cycleOption:focus:not(:focus-visible),
.cycleOption_mobile:focus:not(:focus-visible) {
  outline: none;
}

.cycleOption:focus-visible,
.cycleOption_mobile:focus-visible {
}

.icon {
  border-radius: 20px;
  background-color: var(--ion-color-light);
  border: 2px solid var(--ion-color-light);
}

.icon_active {
  border: none;
  font-size: 27px;
  border-radius: 27px;
}

.phase,
.desc {
  color: var(--grey-600);
}

.desc {
  padding-top: 10px;
  max-width: 198px;
}

.line {
  position: absolute;
  height: 2px;
  width: calc(100% + var(--ion-grid-column-padding) * 2);
  left: 50%;
  background: var(--grey-400);
  z-index: -1;
}

.line_mobile {
  width: 2px;
  height: 100%;
  left: calc(55px / 2 + var(--ion-grid-column-padding));
  top: calc(20px / 2 + var(--ion-grid-column-padding));
}

.cycleOption:last-of-type .line {
  width: 0;
}

.textContainer_mobile {
  display: flex;
  flex-direction: column;
}

.cycleOption_mobile .desc {
  padding-top: 0;
  padding-left: 10px;
}
