.lightWeight {
  font-weight: 200;
}

.normalWeight {
  font-weight: normal;
}

.boldWeight {
  font-weight: bold;
}

.bolderWeight {
  font-weight: 900;
}

.size10 {
  font-size: 10px;
}

.size12 {
  font-size: 12px;
}

.size14 {
  font-size: 14px;
  line-height: 21px;
}

.size16 {
  font-size: 16px;
}

.size18 {
  font-size: 18px;
}

.size21 {
  font-size: 21px;
}
