.button {
  height: 43px;
  width: 213px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  text-transform: none;
  letter-spacing: normal;
}
