.textButton {
  font-weight: bold!important;
  font-size: 14px;
  --background-hover: transparent!important;
  --background-focused: transparent;
  --padding-start: 0!important;
  --padding-end: 0!important;
  /* we can remove hover background using property below
   * but current text buttons in Tuune guideline don't follow
   * Material Design patterns
  */
  /*--background-hover-opacity: 0;*/
}

.textButton .textButton__text {
  line-height: 13px;
  margin-left: 7px;
}
