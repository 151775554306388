.checkboxContainer {
  width: 100%;
  --background: transparent;
  --background-hover: transparent;
  --ripple-color: transparent;
  --background-focused: transparent;
  --background-activated: transparent;
  --min-height: 20px;
  --padding-start: 0;
  --inner-padding-end: 0;
  position: relative;
}

.checkbox {
  --border-radius: 3px;
  --border-width: 1px;
  margin: 0 10px 0 0;
  width: 26px;
  height: 26px;
  flex-shrink: 0;
}

.error {
  --border-color: var(--ion-color-danger);
}

.checkboxContainer p {
  margin-bottom: 0;
  margin-top: 0;
  line-height: normal;
}

.labelContainer {
  display: flex;
}

.checkboxInnerContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}
