.errorMsgContainer {
  display: flex;
  margin-top: 12px;
}

.errorMsgContainer + .errorMsgContainer {
  margin-top: 0;
}

.errorMsg {
  padding-left: 10px;
  white-space: pre-line;
}

.errorIcon {
  font-size: 20px;
  min-width: 18px;
}

.absolute {
  position: absolute;
}
